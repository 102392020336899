<template>
  <div class="p-my-6 p-px-3">
    <h3 style="font-size: 18px" class="p-text-center">
      {{ $t("home.ourSub") }}
    </h3>
    <p style="font-size: 25px" class="p-text-center p-text-bold p-mb-0">
      {{ $t("home.choose") }}<br />
    </p>
    <div class="p-text-center">
      {{ $t("home.choose0") }}<br />
      {{ $t("home.choose1") }}
    </div>
    <div
      class="p-d-flex p-jc-around p-flex-wrap p-ai-start p-my-4"
      style="width: 100%"
    >
      <Card v-for="sub in subscriptions" :key="sub.id" class="p-my-2">
        <template #header>
          <div class="top-bar"></div>
        </template>
        <template #title>
          {{ $tm(`home.subscriptions`)[sub.id] }}
        </template>
        <template #subtitle>
          {{
            $t(`home.duration${sub.id > 0 ? 1 : 0}`, { duration: sub.duration })
          }}
        </template>
        <template #content>
          <Chip class="p-px-3" :label="sub.cost + ' ' + sub.currency" />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useSubscriptions } from "@/graphql/subscription/subscriptions";
export default defineComponent({
  name: "HomeSubscription",
  setup() {
    return {
      ...useSubscriptions(),
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.p-card) {
  position: relative;
  min-width: 160px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 25px 100px 0 rgba(69, 88, 157, 0.08);
  border: 1px solid #363660;
  .top-bar {
    width: 50%;
    height: 4px;
    background: #363660;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -5px);
    border-radius: 10px 10px 0 0;
  }
  .p-card-content {
    padding: 8px;
  }
}
</style>
