
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeFeature",
  setup() {
    return {
      items: [
        {
          title: "activity",
          img: "activity.png",
          reverse: false,
          body: [
            "Gérez plusieurs sociétés à partir d'un seul compte",
            "Créez votre équipe (gérants, magasiniers, caissiers)",
            "Gérez vos terminaux",
          ],
        },
        {
          title: "product",
          img: "product.png",
          reverse: true,
          body: [
            "Créez vos produits stockables ou services",
            "Placez vos produits dans des catégories et sous catégories illimitées",
            "Gérez vos dates d'expirations et définissez un délais d'avertissement",
            "Finit les rupture de stock en définissant un seuil de stock",
            "Finit les problemes de ravitaillement à prix ou dates différentes grâce à la gestion de stocks multiples",
            "Faites vos inventaires et gérez vos documents de stock",
          ],
        },
        {
          title: "customer",
          img: "customer.png",
          reverse: false,
          body: [
            "Suivez vos clients actifs et relancez vos clients inactifs",
            "Suivez le chiffre d'affaire réalisé par client avec des rapports détaillés",
          ],
        },
        {
          title: "expense",
          img: "expense.jpg",
          reverse: true,
          body: [
            "Enregistrez vos dépenses avec la possibilité de créer des catégories de dépenses",
            "Obtenez un rapport détaillé de vos dépenses avec un comparatif recettes/dépenses",
          ],
        },
        {
          title: "pos2",
          img: "pos.jpg",
          reverse: false,
          body: [
            "Gerez de multiples points de vente grâce à la gestion multi-terminaux",
            "Interface de vente moderne compatible avec les écrans digitaux",
            "Supporte la gestion d'imprimantes tickets, lecteurs codes barres et tiroirs de caisse",
            "Gestion des crédits, encaissements et differents modes paiements",
            "Gestion de mise en attente",
          ],
        },
      ],
    };
  },
});
