
import { defineComponent } from "vue";
import HomeFooter from "@/components/home/HomeFooter.vue";
import HomeFeature from "@/components/home/HomeFeature.vue";
import HomeSubscription from "@/components/home/HomeSubscription.vue";
export default defineComponent({
  name: "HomeLayout",
  components: { HomeFooter, HomeFeature, HomeSubscription },
  setup() {
    return {
      items: ["BG-INDEX3.jpg"],
    };
  },
});
